<template>
  <div class="goTop">
    <el-backtop target=".goTop">
      <i class="el-icon-caret-top"></i>
    </el-backtop>
    <Header :title="$t('message.link.visit')" />
    <div class="box">
      <img
        src="http://cdn.topartsintermational.com/visit-1-s.jpg"
        width="100%"
      />
      <div class="part part1">
        <h1>{{ setting.part1.title }}</h1>
        <div class="des">{{ setting.part1.des }}</div>
        <div class="cont">{{ setting.part1.cont1 }}</div>
        <img
          :src="setting.item.url"
          width="100%"
          style="margin-top: 26px"
          @click="handleVisible(setting.item.link)"
        />
        <div class="picList">
          <div class="item">
            <img
              src="http://cdn.topartsintermational.com/visit-3-s.jpg"
              width="100%"
            />
          </div>
          <div class="item">
            <img
              src="http://cdn.topartsintermational.com/visit-4-s.jpg"
              width="100%"
            />
          </div>
          <div class="item">
            <img
              src="http://cdn.topartsintermational.com/visit-5-s.jpg"
              width="100%"
            />
          </div>
          <div class="item">
            <img
              src="http://cdn.topartsintermational.com/visit-6-s.jpg"
              width="100%"
            />
          </div>
        </div>
        <div class="horse">
          <div class="horse-cont">
            <p>{{ setting.part1.cont2 }}</p>
          </div>
        </div>
        <div class="threePic">
          <div class="left">
            <div class="left-item">
              <img
                :src="setting.item1.left1.url"
                width="100%"
                height="275px"
                @click="handleVisible(setting.item1.left1.link)"
              />
            </div>
            <div class="left-item">
              <img
                :src="setting.item1.left2.url"
                width="100%"
                height="275px"
                @click="handleVisible(setting.item1.left2.link)"
              />
            </div>
          </div>
          <div class="right">
            <img
              :src="setting.item1.right.url"
              width="100%"
              height="566px"
              @click="handleVisible(setting.item1.right.link)"
            />
          </div>
        </div>
        <div class="part1-three">
          <div class="three-item" v-for="item in setting.list1" :key="item.id">
            <img
              :src="item.url"
              width="100%"
              @click="handleVisible(item.link)"
            />
          </div>
        </div>
      </div>
      <div class="part part2">
        <h1>{{ setting.part2.title }}</h1>
        <img
          src="http://cdn.topartsintermational.com/visit-14.jpg"
          width="100%"
        />
        <div class="part-wall">
          <div
            class="part-wall-item"
            v-for="item in setting.list2"
            :key="item.id"
          >
            <img
              :src="item.url"
              width="100%"
              @click="handleVisible(item.link)"
            />
          </div>
        </div>
      </div>
      <div class="part part3">
        <h1>{{ setting.part3.title }}</h1>
        <div class="des">{{ setting.part3.des }}</div>
        <div class="part3-cont">
          <p>{{ setting.part3.cont }}</p>
        </div>
        <div class="part-wall">
          <div
            class="part-wall-item"
            v-for="item in setting.list3"
            :key="item.id"
          >
            <img
              :src="item.url"
              width="100%"
              @click="handleVisible(item.link)"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
    <!-- 模态框 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="1200px"
      :show-close="false"
      custom-class="dialog"
      destroy-on-close
    >
      <video
        src="http://cdn.topartsintermational.com/visit-fir-s.mp4"
        controls
        width="1200px"
        style="display: block"
      ></video>
    </el-dialog>
    <el-dialog
      :visible.sync="visible"
      width="1200px"
      :show-close="false"
      custom-class="dialog"
      destroy-on-close
    >
      <video :src="link" controls width="1200px" style="display: block"></video>
    </el-dialog>
  </div>
</template>

<script>
import Header from '../../components/header'
import Footer from '../../components/footer'
export default {
  name: "Visit",
  components: {
    Header,
    Footer,
  },
  data () {
    return {
      setting: {
        part1: {
          title: this.$t('message.visit.part1.title'),
          des: this.$t('message.visit.part1.des'),
          cont1: this.$t('message.visit.part1.cont1'),
          cont2: this.$t('message.visit.part1.cont2'),
        },
        part2: {
          title: this.$t('message.visit.part2.title'),
        },
        part3: {
          title: this.$t('message.visit.part3.title'),
          des: this.$t('message.visit.part3.des'),
          cont: this.$t('message.visit.part3.cont'),
        },
        item: {
          url: 'http://cdn.topartsintermational.com//visit-2.jpg',
          link: 'http://cdn.topartsintermational.com/visit-1.mp4',
        },
        item1: {
          left1: {
            url: 'http://cdn.topartsintermational.com/visit-8.jpg',
            link: 'http://cdn.topartsintermational.com/visit-2.mp4',
          },
          left2: {
            url: 'http://cdn.topartsintermational.com/visit-10.jpg',
            link: 'http://cdn.topartsintermational.com/visit-3.mp4',
          },
          right: {
            url: 'http://cdn.topartsintermational.com/visit-9.jpg',
            link: 'http://cdn.topartsintermational.com/visit-4.mp4',
          },
        },
        list1: [
          {
            id: 1,
            url: 'http://cdn.topartsintermational.com/visit-11.jpg',
            link: 'http://cdn.topartsintermational.com/visit-5.mp4',
          },
          {
            id: 2,
            url: 'http://cdn.topartsintermational.com/visit-12.jpg',
            link: 'http://cdn.topartsintermational.com/visit-6.mp4',
          },
          {
            id: 3,
            url: 'http://cdn.topartsintermational.com/visit-13.jpg',
            link: 'http://cdn.topartsintermational.com/visit-7.mp4',
          }
        ],
        list2: [
          {
            id: 1,
            url: 'http://cdn.topartsintermational.com/visit-15.jpg',
            link: 'http://cdn.topartsintermational.com/visit-8.mp4',
          },
          {
            id: 2,
            url: 'http://cdn.topartsintermational.com/visit-16.jpg',
            link: 'http://cdn.topartsintermational.com/visit-9.mp4',
          },
          {
            id: 3,
            url: 'http://cdn.topartsintermational.com/visit-17.jpg',
            link: 'http://cdn.topartsintermational.com/visit-10.mp4',
          },
          {
            id: 4,
            url: 'http://cdn.topartsintermational.com/visit-18.jpg',
            link: 'http://cdn.topartsintermational.com/visit-11.mp4',
          },
          {
            id: 5,
            url: 'http://cdn.topartsintermational.com/visit-19.jpg',
            link: 'http://cdn.topartsintermational.com/visit-12.mp4',
          },
          {
            id: 6,
            url: 'http://cdn.topartsintermational.com/visit-20.jpg',
            link: 'http://cdn.topartsintermational.com/visit-13.mp4',
          },
          {
            id: 7,
            url: 'http://cdn.topartsintermational.com/visit-21.jpg',
            link: 'http://cdn.topartsintermational.com/visit-14.mp4',
          },
          {
            id: 8,
            url: 'http://cdn.topartsintermational.com/visit-22.jpg',
            link: 'http://cdn.topartsintermational.com/visit-15.mp4',
          }
        ],
        list3: [
          {
            id: 1,
            url: 'http://cdn.topartsintermational.com/visit-24-s.jpg',
            link: 'http://cdn.topartsintermational.com/visit-16.mp4',
          },
          {
            id: 2,
            url: 'http://cdn.topartsintermational.com/visit-25-s.jpg',
            link: 'http://cdn.topartsintermational.com/visit-17.mp4',
          },
          {
            id: 3,
            url: 'http://cdn.topartsintermational.com/visit-26-s.jpg',
            link: 'http://cdn.topartsintermational.com/visit-18.mp4',
          },
          {
            id: 4,
            url: 'http://cdn.topartsintermational.com/visit-27-s.jpg',
            link: 'http://cdn.topartsintermational.com/visit-19.mp4',
          },
          {
            id: 5,
            url: 'http://cdn.topartsintermational.com/visit-28-s.jpg',
            link: 'http://cdn.topartsintermational.com/visit-20.mp4',
          },
          {
            id: 6,
            url: 'http://cdn.topartsintermational.com/visit-29-s.jpg',
            link: 'http://cdn.topartsintermational.com/visit-21.mp4',
          },
          {
            id: 7,
            url: 'http://cdn.topartsintermational.com/visit-30-s.jpg',
            link: 'http://cdn.topartsintermational.com/visit-22.mp4',
          },
          {
            id: 8,
            url: 'http://cdn.topartsintermational.com/visit-31-s.jpg',
            link: 'http://cdn.topartsintermational.com/visit-23.mp4',
          }
        ],
      },
      dialogVisible: true,
      visible: false,
      link: '',
    }
  },
  methods: {
    handleVisible (link) {
      this.visible = true
      this.link = link
    }
  }
};
</script>

<style scoped lang="less">
p {
  margin: 0;
  padding: 0;
}
.goTop {
  min-width: 1240px;
  height: 100vh;
  overflow-x: hidden;
}
.box {
  width: 100%;
  background: url("http://cdn.topartsintermational.com/visit-bg.jpg") no-repeat;
  background-size: cover;
}
.part {
  width: 1200px;
  margin: auto;
  color: #fff;
  text-align: center;
  h1 {
    font-size: 26px;
    margin: 0;
    line-height: 48px;
  }
  .des {
    font-size: 18px;
    line-height: 26px;
  }
  .cont {
    font-size: 20px;
    text-indent: 2em;
    line-height: 28px;
    margin-top: 16px;
  }
  .part-wall {
    margin-top: 16px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .part-wall-item {
      width: 24%;
      overflow: hidden;
      &:nth-child(n + 5) {
        margin-top: 8px;
      }
      img {
        transition: all 0.6s;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }
}
.picList {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  .item {
    width: 24%;
    overflow: hidden;
    img {
      transition: all 0.6s;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
}
.horse {
  width: 100%;
  height: 580px;
  background: url("http://cdn.topartsintermational.com/visit-7.jpg") no-repeat;
  background-size: cover;
  padding: 50px 0 0 80px;
  box-sizing: border-box;
  margin-top: 16px;
  .horse-cont {
    width: 380px;
    height: 400px;
    background: url("http://cdn.topartsintermational.com/warriors-img2.jpg")
      no-repeat;
    background-size: cover;
    font-size: 16px;
    text-indent: 2em;
    line-height: 28px;
    padding: 60px 30px 0;
    text-align: left;
  }
}
.threePic {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  .left {
    width: 49%;
    display: flex;
    flex-wrap: wrap;
    .left-item {
      &:nth-child(2) {
        margin-top: 16px;
      }
      overflow: hidden;
      img {
        display: block;
        transition: all 0.6s;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }
  .right {
    width: 49%;
    overflow: hidden;
    img {
      transition: all 0.6s;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
}
.part1-three {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  .three-item {
    width: 33%;
    overflow: hidden;
    img {
      transition: all 0.6s;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
}

.part2 {
  margin-top: 40px;
}

.part3 {
  margin: 40px auto;
  .part3-cont {
    margin-top: 16px;
    width: 100%;
    height: 400px;
    background: url("http://cdn.topartsintermational.com/visit-23.jpg")
      no-repeat;
    background-size: cover;
    p {
      font-size: 20px;
      text-indent: 2em;
      line-height: 50px;
      text-align: left;
      box-sizing: border-box;
      padding: 140px 80px 0;
    }
  }
}
</style>